<template>
    <base-layout title="健康管理服务平台" :logo="logo" @getMenu="getMenu">
        <el-aside width="180px">
            <aside-menu :default-active="$route.path" :menuItems="menuItems"/>
        </el-aside>
        <el-main>
            <router-view></router-view>
        </el-main>
    </base-layout>
</template>

<script>
    import Logo from "@/assets/mlogo.png"
    import BaseLayout from "./components/baseLayout"
    import AsideMenu from "./components/asideMenu"
    import menu from "@/data/menu"

    export default {
        components: {
            BaseLayout,
            AsideMenu
        },
        data() {
            return {
                logo: Logo,
                menuItems: []
            }
        },
        methods: {
            async getMenu(type,path=null) {
                this.loading = true
                let userType = this.$store.getters.userType;
                // if(userType != "1") {
                const resp = await this.$http.get(`/auth/menu/`)
                if (resp.data.data.permission.length > 0) {
                    localStorage.setItem('btnPermissions', JSON.stringify(resp.data.data.permission))
                } else {
                    localStorage.setItem('btnPermissions', JSON.stringify([]))
                }
                if (resp.data.data.menuIds.length > 0) {
                    let menuList = []
                    if (userType == '3' || userType == '5') {
                        menuList = menu.doctorMenuItems
                    }
                    if (userType == '1' || userType == '4') {
                        if (type == 'doctor') {
                            menuList = menu.doctorMenuItems
                        }
                        if (type == 'company') {
                            menuList = menu.menuItems
                        }
                    }
                    if (userType == '2') {
                        menuList = menu.menuItems
                    }
                    this.menuItems = menu.filterArrForKey(menuList, 'id', resp.data.data.menuIds)
                } else {
                    this.menuItems = []
                }
                // }
                // else {
                //     if (type == 'doctor') {
                //         this.menuItems = menu.doctorMenuItems
                //     }
                //     if (type == 'company') {
                //         this.menuItems = menu.menuItems
                //     }
                // }

                if (!path){
                    if(this.menuItems.length){
                        if(this.menuItems.children){
                            this.$router.push(this.menuItems[0].children[0].path)
                        }else {
                            this.$router.push(this.menuItems[0].path)
                        }
                    }
                }else {
                    this.$router.push(path)
                }
                localStorage.setItem('type', type)
                this.loading = false
            },
        },
        mounted() {
            let type = localStorage.getItem('type') || 'doctor'
            this.getMenu(type, this.$route.path)
        }
    }
</script>

<style lang="scss" scoped>
    .el-aside {
        /*background-color: #eeeeee;*/
        color: #333;
        border-right: 1px solid #e6e6e6;
    }
</style>
