const data = {
    doctorMenuItems: [
        {
            id: '10',
            label: '医生工作台',
            path: '/main/dashboard',
            icon: 'el-icon-view',
        },
        {
            id: '20',
            label: '健康档案',
            path: '/main/phr',
            icon: 'el-icon-brush',
            children: [
                {
                    id: '20-1',
                    label: '档案进度',
                    path: '/main/phr/progress',
                },
                {
                    id: '20-2',
                    label: '健康档案',
                    path: '/main/phr',
                },
            ]
        },
        {
            id: '30',
            label: '健康评估',
            path: '/main/healthAssessment/hra3',
            icon: 'el-icon-document',
        },
        {
            id: '40',
            label: '心理评估',
            path: '/main/assessment/person',
            icon: 'el-icon-star-off',
        },
        {
            id: '50',
            label: '健康干预',
            path: '/main/hi',
            icon: 'el-icon-s-opportunity',
            children: [
                {
                    id: '50-1',
                    label: '干预方案',
                    path: '/main/hi/task',
                },
                {
                    id: '50-2',
                    label: '干预模板',
                    path: '/main/hi/model',
                },
            ]
        },
        {
            id: '60',
            label: '问卷量表',
            path: '/main/psychological',
            icon: 'el-icon-data-line',
            children: [
                {
                    id: '60-5',
                    label: '五高问卷',
                    path: '/main/evaluate/fivehighs',
                },
                {
                    id: '60-1',
                    label: '常规问卷',
                    path: '/main/evaluate/common',
                },
                {
                    id: '60-2',
                    label: '健康初筛',
                    path: '/main/evaluate/chushai',
                },
                {
                    id: '60-3',
                    label: 'SLC90心理评测',
                    path: '/main/evaluate/slc90',
                },
                {
                    id: '60-4',
                    label: '自定义问卷',
                    path: '/main/evaluate/custom',
                },
            ]
        },
        {
            id: '70',
            label: '健康小屋',
            path: '/main/hut',
            icon: 'el-icon-school',
            children: [
                {
                    id: '70-1',
                    label: '报告管理',
                    path: '/main/hut/report',
                },
                {
                    id: '70-2',
                    label: '危急值监控管理',
                    path: '/main/hut/weijizhi',
                },
                {
                    id: '70-3',
                    label: '单项测量',
                    path: '/main/hut/dxcl',
                }, {
                    id: '70-4',
                    label: '小屋清单列表',
                    path: '/main/hut/hut',
                }, {
                    id: '70-5',
                    label: '设备清单列表',
                    path: '/main/hut/device',
                // }, {
                //     id: '220-6',
                //     label: '设备池管理',
                //     path: '/main/hi/log',
                },
            ]
        },
        {
            id: '80',
            label: '随访管理',
            path: '/main/follow-up',
            icon: 'el-icon-service',
            children: [
                {
                    id: '80-1',
                    label: '随访任务',
                    path: '/main/follow-up/task',
                },
                {
                    id: '80-2',
                    label: '随访记录',
                    path: '/main/follow-up/log',
                },
            ]
        },
        {
            id: '90',
            label: '阳性跟踪',
            path: '/main/positive',
            icon: 'el-icon-odometer',
            children: [
                {
                    id: '90-1',
                    label: '首次跟踪',
                    path: '/main/positive/new',
                },
                {
                    id: '90-2',
                    label: '跟踪计划',
                    path: '/main/positive/task',
                },
                {
                    id: '90-3',
                    label: '跟踪记录',
                    path: '/main/positive/log',
                },
                {
                    id: '90-4',
                    label: '预警配置',
                    path: '/main/positive/setting',
                },
            ]
        },
        {
            id: '100',
            label: '膳食管理',
            path: '/main/diet',
            icon: 'el-icon-food',
            children: [
                {
                    id: '100-1',
                    label: '膳食方案',
                    path: '/main/diet/task',
                },
                {
                    id: '100-2',
                    label: '膳食原则',
                    path: '/main/diet/principles',
                },
                {
                    id: '100-3',
                    label: '中医食疗',
                    path: '/main/diet/dtcm',
                },
                {
                    id: '100-4',
                    label: '菜谱模板',
                    path: '/main/diet/cookbook',
                },
                {
                    id: '100-5',
                    label: '原料库',
                    path: '/main/diet/ingredients',
                },
                {
                    id: '100-6',
                    label: '成品菜',
                    path: '/main/diet/dishes',
                },
            ]
        },
        {
            id: '110',
            label: '运动管理',
            path: '/main/fitness',
            icon: 'el-icon-basketball',
            children: [
                {
                    id: '110-1',
                    label: '运动方案',
                    path: '/main/fitness/task',
                },
                {
                    id: '110-2',
                    label: '运动原则',
                    path: '/main/fitness/principles',
                },
                {
                    id: '110-3',
                    label: '运动方案模板',
                    path: '/main/fitness/template',
                },
                {
                    id: '110-4',
                    label: '运动项目',
                    path: '/main/fitness/items',
                },
            ]
        },
        {
            id: '115',
            label: '职业健康',
            path: '/main/industrial',
            icon: 'el-icon-brush',
            children: [
                {
                    id: '115-1',
                    label: '职业健康档案',
                    path: '/main/industrial/data',
                },
                {
                    id: '115-2',
                    label: '检查结果查询',
                    path: '/main/industrial/report',
                },
                {
                    id: '115-3',
                    label: '岗位适配度',
                    path: '/main/industrial/adapter',
                },
                {
                    id: '115-4',
                    label: '岗位调整',
                    path: '/main/industrial/trans',
                },
                {
                    id: '115-5',
                    label: '汇总统计',
                    path: '/main/industrial/statistics',
                },
            ]
        }
    ],
    menuItems: [
        {
            id: '120',
            label: '系统首页',
            path: '/company/dashboard',
            icon: 'el-icon-s-home',
        },
        {
            id: '130',
            label: '管理员管理',
            path: '/company/user',
            icon: 'el-icon-user',
        },
        {
            id: '140',
            label: '专家库信息',
            path: '/company/expert',
            icon: 'el-icon-s-custom',
        },
        {
            id: '150',
            label: '人员管理',
            path: '/company/account',
            icon: 'el-icon-user-solid',
            children: [
                {
                    id: '150-1',
                    label: '人员清单',
                    path: '/company/account',
                    icon: 'el-icon-user-solid',
                },
                {
                    id: '150-2',
                    label: '人员变更',
                    path: '/company/account/transfer',
                    icon: 'el-icon-refresh',
                },
            ]
        },
        {
            id: '160',
            label: '单位管理',
            path: '/company/company',
            icon: 'el-icon-office-building',
        },
        {
            id: '170',
            label: '健康设施',
            path: '/company/facility',
            icon: 'el-icon-setting',
        },
        {
            id: '200',
            label: '健康包保管理',
            path: '/company/healthInsurance',
            icon: 'el-icon-folder-add',
        },
        {
            id: '210',
            label: '大厅管理',
            path: '/company/hall',
            icon: 'el-icon-folder-add',
            children: [
                {
                    id: '210-1',
                    label: '医生排班',
                    path: '/company/hall/scheduling',
                    icon: 'el-icon-user-solid',
                },
                {
                    id: '210-2',
                    label: '工作量',
                    path: '/company/hall/workload',
                    icon: 'el-icon-refresh',
                },
            ]
        },
        {
            id: '220',
            label: '健康宣教',
            path: '/company/jkxj',
            icon: 'el-icon-folder-add',
            children: [
                {
                    id: '220-1',
                    label: '健康宣教',
                    path: '/company/jkxj/data',
                    icon: 'el-icon-user-solid',
                },
            ]
        },
        {
            id: '230',
            label: '健康达人',
            path: '/company/jkdr',
            icon: 'el-icon-folder-add',
            children: [
                {
                    id: '230-1',
                    label: '健康达人',
                    path: '/company/jkdr/data',
                    icon: 'el-icon-user-solid',
                },
                {
                    id: '230-2',
                    label: '汇总统计',
                    path: '/company/jkdr/statistics',
                    icon: 'el-icon-user-solid',
                },
            ]
        },
        {
            id: '180',
            label: '角色管理',
            path: '/company/role',
            icon: 'el-icon-s-operation',
        },
        // {
        //     id: '200',
        //     label: '数据统计',
        //     path: '/company/statistics/',
        //     icon: 'el-icon-data-analysis',
        //     children: [
        //         {
        //             id: '200-1',
        //             label: '预分组统计',
        //             path: '/company/statistics/yufenzu',
        //         },
        //         {
        //             id: '200-2',
        //             label: '大病人员',
        //             path: '/company/statistics/dabing',
        //             icon: 'el-icon-refresh',
        //         },
        //         {
        //             id: '200-3',
        //             label: '慢病人员',
        //             path: '/company/statistics/manbing',
        //             icon: 'el-icon-refresh',
        //         },
        //     ]
        // },
        {
            id: '190',
            label: '操作日志',
            path: '/main/oplog',
            icon: 'el-icon-notebook-1',
        }
    ],
    toList() {
        let newArr = []
        this.menuItems.forEach(e => {
            newArr.push({id: e.id, menuName: e.label})
            if (e.children) {
                e.children.forEach(c => {
                    newArr.push({id: c.id, menuName: c.label, parentMenuId: e.id})
                })
            }
        })
        return newArr
    },
    filterArrForKey(arr, key, value) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterArrForKey(item.children, key, value)
                }
            }
            if (value.includes(item[key])) {
                return item
            } else {
                return null
            }
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    },
    filterChecked(arr, key) {
        let newArr = []
        newArr = arr.map(item => {
            if (item.children && item.children != null) {
                item = {
                    ...item,
                    children: this.filterChecked(item.children)
                }
            }
            return item[key]
        })
        newArr = newArr.filter(item => item != null)
        return newArr
    }
}

export default data

