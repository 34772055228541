<template>
    <el-container>
        <el-header class="header">
            <div class="logo">
                <img src="../../../assets/mlogo.png" alt="logo">
                <span> 健康管理服务平台 </span>
                <el-radio-group v-model="type" size="small" style="display: flex;padding: 0 10px"
                                @change="handelDoctor"
                                v-if="$store.getters.userType=='1' || $store.getters.userType=='4'">
                    <el-radio-button class="radio-btn" label="doctor">医生端</el-radio-button>
                    <el-radio-button class="radio-btn" label="company">企业端</el-radio-button>
                </el-radio-group>
            </div>
            <div class="head">
            </div>
            <el-header class="header-right">
                <router-link class="iconButton" to="/main/tools/test" v-if="$store.getters.username  == 'tjadmin'">
                    <i class="el-icon-s-opportunity"></i>
                </router-link>
                <router-link class="iconButton" to="/main/message">
                    <el-badge value="..." class="badge" v-if="$store.state.messages.length > 0">
                        <i class="el-icon-chat-line-round"></i>
                    </el-badge>
                    <i class="el-icon-chat-line-round" v-else></i>
                </router-link>
                <router-link class="iconButton" to="/main/call">
                    <el-badge value="..." class="badge" v-if="$store.state.messages.length > 0">
                        <i class="el-icon-bell"></i>
                    </el-badge>
                    <i class="el-icon-bell" v-else></i>
                </router-link>
                <router-link class="iconButton" to="/main/message">
                    <el-badge :value="smsCount" class="badge" v-if="smsCount > 0">
                        <i class="el-icon-message"></i>
                    </el-badge>
                    <i class="el-icon-message" v-else></i>
                </router-link>
                <router-link class="iconButton" to="/main/help">
                    <i class="el-icon-help"></i>
                </router-link>
                <div class="iconButton" @click="getData">
                    <img src="./wechat.png" style="margin-top: 18px"/>
                </div>
                <div class="iconButton" style="border-left:none" @click="screen">
                    <i class="el-icon-full-screen"></i>
                </div>
                <el-dropdown trigger="click" class="dropdownMenu">
                    <span class="username">
                        <!-- <img src="//cdn.zhack.com/zkui/img/avatar.png" class="user-avatar"/> -->
                        <!-- <el-avatar size="small" src="//cdn.zhack.com/zkui/img/avatar.png"></el-avatar> -->
                        {{ $store.getters.username }}
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <router-link class="link" to="/main">
                            <el-dropdown-item>首页</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/main/password">
                            <el-dropdown-item>修改密码</el-dropdown-item>
                        </router-link>
                        <router-link class="link" to="/logout">
                            <el-dropdown-item divided>退出登陆</el-dropdown-item>
                        </router-link>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-header>
        </el-header>
        <el-container>
            <slot></slot>
        </el-container>
        <el-dialog
                title="扫码绑定公众号"
                :visible.sync="dialogVisible"
                width="30%" style="text-align: center">
            <img :src="form.url" style="width: 200px">
            <p v-if="form.openid" style="color: #67c23a">已关注</p>
            <p v-else style="color: red">未关注<span>请扫描关注</span></p>
            <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
        </el-dialog>
    </el-container>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                default: '佐相科技'
            }
        },
        data() {
            return {
                dialogVisible: false,
                form: {},
                type: localStorage.getItem('type') || 'doctor',
                smsCount: 0,
                timer: null
            }
        },
        mounted() {
            let openid = localStorage.getItem('openid') || ''
            if (!openid || openid == "null") {
                this.getData()
            }
        },
        created() {
            // 在组件创建时设置定时器
            this.getSystemSms()
            this.timer = setInterval(this.getSystemSms, 30000);
        },
        beforeDestroy() {
            // 在组件销毁前清除定时器，以防止内存泄漏
            clearInterval(this.timer);
        },
        methods: {
            async getData() {
                const resp = await this.$http.get(`/wechat/qrcode/getWXPublicQRCode`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.form = resp.data.data
                    this.dialogVisible = true
                }
            },
            async getSystemSms() {
                const resp = await this.$http.get(`/system/count`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.smsCount = resp.data.total
                }
            },
            handelDoctor(type) {
                this.type = type
                this.$emit('getMenu', type);
            },
            screen() {
                let element = document.documentElement;
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = !this.fullscreen;
            },
            doLogout() {
                this.$router.push({path: '/'})
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style>
    ::-webkit-scrollbar {
        width: 4px;
        height: 8px;
    }

    /* 滚动条有滑块的轨道部分 */
    ::-webkit-scrollbar-track-piece {
        background-color: transparent;
        border-radius: 5px;
    }

    /* 滚动条滑块(竖向:vertical 横向:horizontal) */
    ::-webkit-scrollbar-thumb {
        cursor: pointer;
        background-color: rgba(144, 147, 153, .3);
        border-radius: 5px;
    }

    /* 滚动条滑块hover */
    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(144, 147, 153, .3);
    }

    /* 同时有垂直和水平滚动条时交汇的部分 */
    ::-webkit-scrollbar-corner {
        display: block;
        /* 修复交汇时出现的白块 */
    }
</style>
<style lang="scss" scoped>
    .header {
        background: #2081c0;
        color: #fff;
        display: flex;
        flex-direction: row;
        height: 60px;
        line-height: 60px;

        .logo {
            /*width: 240px;*/
            vertical-align: middle;
            display: flex;
            align-items: center;
            color: #ffffff;
            text-decoration: none;

            img {
                width: 35px;
                height: 35px;
                border-radius: 5px;
                margin-right: 10px;
            }
        }

        .head {
            flex: 1;
        }

        .user {
            text-align: right;
            width: 180px;

            .username {
                color: #ffffff;
                cursor: pointer;
                vertical-align: middle;
                display: flex;
                align-items: center;

                .user-avatar {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        .header-right {
            padding: 0;
            line-height: 60px;
            color: #333;
            // border-bottom: 1px solid #eeeeee;
            display: flex;
            flex-direction: row;

            .iconButton {
                width: 50px;
                margin: 2px;
                padding: 0;
                text-align: center;
                cursor: pointer;
                // border-left: 1px solid #eeeeee;
                // border-right: 1px solid #eeeeee;
                color: #fff;

                .el-badge {
                    line-height: 20px;
                    height: 20px;
                    padding: 0
                }

                i {
                    font-size: 120%;
                    color: white;
                }
            }

            .dropdownMenu {
                margin-left: 10px;
                cursor: pointer;
                color: #fff;

                .user-avatar {
                    width: 25px;
                    height: 25px;
                }
            }
        }

    }

    .link {
        text-decoration: none;
    }
</style>

<style scoped>
    /* ::v-deep .radio-btn > .el-radio-button__inner{
        background-color: #2081c0 !important;
        color: #ccc;
        border: none;
        box-shadow: none;
        padding: 10px 5px;
    }
    ::v-deep .is-active> .el-radio-button__inner{
        color: #fff !important;
    } */
</style>
